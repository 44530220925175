import { t } from "@/i18n-js/instance";
import { Icon } from "@circle-react-shared/Icon";
import { Typography } from "@circle-react-uikit/Typography";

export const EmptyState = ({ name }: { name?: string }) => (
  <div className="flex h-full flex-col items-center justify-center gap-2">
    <Icon type="20-search-v3" size={24} className="text-disabled !w-6" />
    <div className="w-48 text-center">
      <div className="mr-1">
        <Typography.LabelSm color="text-default">
          {t("chat_space.search_messages.empty_state")}
        </Typography.LabelSm>
      </div>
      <Typography.LabelSm color="text-default">{name || ""}</Typography.LabelSm>
    </div>
  </div>
);
