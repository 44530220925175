import { useCallback } from "react";
import { useQueryClient } from "react-query";

const DEFAULT_PER_PAGE = 20;
export const useInfiniteQueryHelpers = () => {
  const queryClient = useQueryClient();

  const findAndReplaceRecord = useCallback(
    (newRecord, queryKey) => {
      if (!queryKey) {
        throw new Error("queryKey is required");
      }
      return queryClient.setQueryData(queryKey, oldData => ({
        pages: oldData.pages.map((page: any) => ({
          ...page,
          records: page.records.map((record: any) =>
            record.id === newRecord.id ? newRecord : record,
          ),
        })),
        pageParams: oldData.pageParams,
      }));
    },
    [queryClient],
  );

  const removeRecord = useCallback(
    (idToRemove, queryKey) => {
      if (!queryKey) {
        throw new Error("queryKey is required");
      }

      return queryClient.setQueryData(queryKey, oldData => ({
        pages: oldData.pages.map((page: any) => ({
          ...page,
          records: page.records.filter(
            (record: any) => record.id !== idToRemove,
          ),
        })),
        pageParams: oldData.pageParams,
      }));
    },
    [queryClient],
  );

  const updateAllRecords = useCallback(
    (newData, queryKey, defaultPerPage = DEFAULT_PER_PAGE) => {
      if (!queryKey) {
        throw new Error("queryKey is required");
      }
      queryClient.setQueryData(queryKey, (oldData: any) => ({
        ...oldData,
        pages: oldData.pages.map((page: any, index: any) => ({
          ...(page || {}),
          records: newData.slice(
            defaultPerPage * index,
            defaultPerPage * (Number(index) + 1),
          ),
        })),
      }));
    },
    [queryClient],
  );

  return {
    findAndReplaceRecord,
    removeRecord,
    updateAllRecords,
  };
};
